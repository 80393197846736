<script setup>
import Button from '@/Components/Button/Button.vue';
import Title from '@/Components/Title/Title.vue';
import { mapErrors } from '@/utilities';
import { Switch } from '@headlessui/vue';
import { useForm } from '@inertiajs/vue3';
import { range } from 'lodash';
import { inject } from 'vue';

import Money from '@/Components/Input/Money.vue';

const route = inject('route');
const props = defineProps({
    claOptions: Object,
    parentPositionOptions: Object,
});

const form = useForm({
    collective_labour_agreement_id: null,
    parent_id: null,
    sector_code: null,
    name: {
        nl: null,
        en: null,
    },
    description: {
        nl: null,
        en: null,
    },
    wage_schema_code: null,
    wages: {
        ...Object.fromEntries(
            range(16, 22).map((age) => [
                age,
                {
                    active: false,
                    wage: null,
                },
            ])
        ),
    },
    cost_factors: {
        ...Object.fromEntries(
            range(16, 22).map((cost_factor) => [
                cost_factor,
                {
                    active: false,
                    cost_factor: null,
                },
            ])
        ),
    },
});

const sendForm = () => {
    form.transform((data) => ({
        ...data,
        wages: Object.entries(data.wages)
            .filter(([, wage]) => wage.active && wage.wage !== null)
            .map(([age, wage]) => ({
                age: age,
                wage: Math.round(parseFloat(wage.wage)),
            })),
        cost_factors: Object.entries(data.cost_factors)
            .filter(([, cost_factor]) => cost_factor.active && cost_factor.cost_factor !== null)
            .map(([age, cost_factor]) => ({
                age: age,
                cost_factor: cost_factor.cost_factor,
            })),
    })).post(route('staff.positions.store'), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};
</script>

<template>
    <FormKit type="form" @submit="sendForm" :actions="false">
        <Title :title="$t('Add {model}', { model: $t('position') })" />
        <div class="grid mt-4 md:grid-cols-2 gap-x-8 gap-y-2">
            <FormKit
                type="select"
                name="collective_labour_agreement_id"
                :label="$t('CLA')"
                v-model="form.collective_labour_agreement_id"
                :errors="form.errors.collective_labour_agreement_id"
                :value="form.collective_labour_agreement_id"
                :options="claOptions"
                validation="required"
            />
            <FormKit
                type="text"
                name="sector_code"
                :label="$t('Sectorcode')"
                v-model="form.sector_code"
                :errors="form.errors.sector_code"
                :value="form.sector_code"
                validation="required"
            />
            <FormKit
                type="text"
                name="name.en"
                :label="$t('Name (EN)')"
                v-model="form.name.en"
                :errors="form.errors.name?.en"
                :value="form.name.en"
                validation="required"
            />
            <FormKit
                type="text"
                name="name.nl"
                :label="$t('Name (NL)')"
                v-model="form.name.nl"
                :errors="form.errors.name?.nl"
                :value="form.name.nl"
                validation="required"
            />
            <FormKit
                type="textarea"
                name="description.en"
                :label="$t('Description (EN)')"
                v-model="form.description.en"
                :errors="form.errors.description?.en"
                :value="form.description.en"
                validation="required"
            />
            <FormKit
                type="textarea"
                name="description.nl"
                :label="$t('Description (NL)')"
                v-model="form.description.nl"
                :errors="form.errors.description?.nl"
                :value="form.description.nl"
                validation="required"
            />
            <FormKit
                type="text"
                name="wage_schema_code"
                :label="$t('Wage schema code')"
                v-model="form.wage_schema_code"
                :errors="form.errors.wage_schema_code"
                :value="form.wage_schema_code"
                validation="required"
            />
            <FormKit
                type="select"
                name="parent_id"
                :label="$t('Main position')"
                v-model="form.parent_id"
                :errors="form.errors.parent_id"
                :value="form.parent_id"
                :options="parentPositionOptions"
                validation="required"
            />

            <div>
                <label class="block mb-2 text-base leading-tight formkit-label text-blue">
                    {{ $t('Gross wages') }}
                </label>
                <div class="grid gap-y-2">
                    <div class="flex flex-row items-center justify-between space-x-5" v-for="i in range(16, 22)">
                        <Money
                            :label="$t('{value} years', { value: i })"
                            v-model:value="form.wages[i].wage"
                            :disabled="!form.wages[i].active"
                            :placeholder="!form.wages[i].active ? 'N/A' : ''"
                        />
                        <Switch
                            v-model="form.wages[i].active"
                            :class="[
                                form.wages[i].active ? 'bg-blue' : 'bg-blue/30',
                                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue focus:ring-offset-2',
                            ]"
                        >
                            <span class="sr-only">Use setting</span>
                            <span
                                aria-hidden="true"
                                :class="[
                                    form.wages[i].active ? 'translate-x-5' : 'translate-x-0',
                                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                                ]"
                            />
                        </Switch>
                    </div>
                </div>
            </div>
            <div>
                <label class="block mb-2 text-base leading-tight formkit-label text-blue">
                    {{ $t('Cost factor') }}
                </label>
                <div class="grid gap-y-2">
                    <div class="flex flex-row items-center justify-between space-x-5" v-for="i in range(16, 22)">
                        <Money
                            :label="$t('{value} years', { value: i })"
                            v-model:value="form.cost_factors[i].cost_factor"
                            :disabled="!form.cost_factors[i].active"
                            :placeholder="!form.cost_factors[i].active ? 'N/A' : ''"
                        />
                        <Switch
                            v-model="form.cost_factors[i].active"
                            :class="[
                                form.cost_factors[i].active ? 'bg-blue' : 'bg-blue/30',
                                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue focus:ring-offset-2',
                            ]"
                        >
                            <span class="sr-only">Use setting</span>
                            <span
                                aria-hidden="true"
                                :class="[
                                    form.cost_factors[i].active ? 'translate-x-5' : 'translate-x-0',
                                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                                ]"
                            />
                        </Switch>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-row items-center justify-end">
            <Button :processing="form.processing" :text="$t('Save')" />
        </div>
    </FormKit>
</template>
